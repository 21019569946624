export default {
  path: "/exhibitionNewspaperManagement",
  redirect: "/builderManagement",
  meta: {
    icon: "memo",
    title: "展会报馆管理",
    showLink: true,
    rank: 7
  },
  children: [
    {
      path: "/builderManagement",
      name: "BuilderManagement",
      component: () =>
        import(
          "@/views/exhibitionHallManagement/listBuilders/listBuildersPage.vue"
        ),
      meta: {
        title: "搭建商管理"
      },
      children: [
        {
          path: "/builderManagement/details",
          name: "BuilderPositionDetails",
          component: () =>
            import(
              "@/views/exhibitionHallManagement/listBuilders/detail/index.vue"
            ),
          meta: {
            title: "搭建商详情"
          }
        }
      ]
    },
    {
      path: "/newspaperHouseAuditing",
      name: "NewspaperHouseAuditing",
      component: () =>
        import(
          "@/views/exhibitionHallManagement/newspaperHouseAuditing/newspaperHouseAuditingPage.vue"
        ),
      meta: {
        title: "报馆审图"
      },
      children: [
        {
          path: "/newspaperHouseAuditing/baseInfo/details",
          name: "BaseInfoDetails",
          component: () =>
            import(
              "@/views/exhibitionHallManagement/newspaperHouseAuditing/components/BasicInformation/details.vue"
            ),
          meta: {
            title: "信息审核详情"
          }
        },
        {
          path: "/newspaperHouseAuditing/projectCosts/details",
          name: "ProjectCosts",
          component: () =>
            import(
              "@/views/exhibitionHallManagement/newspaperHouseAuditing/components/ProjectCosts/details.vue"
            ),
          meta: {
            title: "项目费用-详情"
          }
        },
        {
          path: "/newspaperHouseAuditing/paymentFees/details",
          name: "PaymentFees",
          component: () =>
            import(
              "@/views/exhibitionHallManagement/newspaperHouseAuditing/components/PaymentFees/details.vue"
            ),
          meta: {
            title: "费用缴纳-详情"
          }
        },
        {
          path: "/newspaperHouseAuditing/drawingReviewMaterials/details",
          name: "DrawingReviewMaterials",
          component: () =>
            import(
              "@/views/exhibitionHallManagement/newspaperHouseAuditing/components/DrawingReviewMaterials/details.vue"
            ),
          meta: {
            title: "审图资料-详情"
          }
        }
      ]
    },
    {
      path: "/newspaperEstablishment",
      name: "NewspaperEstablishment",
      component: () =>
        import(
          "@/views/exhibitionHallManagement/newspaperEstablishment/enter.vue"
        ),
      meta: {
        title: "报馆设置"
      },
      children: [
        {
          path: "/newspaperEstablishment/drawingReviewMaterials/setting",
          name: "DrawingReviewMaterialsDetails",
          component: () =>
            import(
              "@/views/exhibitionHallManagement/newspaperEstablishment/drawingReviewMaterials/detail/index.vue"
            ),
          meta: {
            title: "选项设置"
          }
        },
        {
          path: "/newspaperEstablishment/projectCosts/fieldDetails",
          name: "ProjectCostsDetails",
          component: () =>
            import(
              "@/views/exhibitionHallManagement/newspaperEstablishment/projectCosts/detail/index.vue"
            ),
          meta: {
            title: "项目费用详情"
          }
        },
        {
          path: "/newspaperEstablishment/systemAnnouncement/addNotice",
          name: "AddNotice",
          component: () =>
            import(
              "@/views/exhibitionHallManagement/newspaperEstablishment/systemAnnouncement/addNotice/enter.vue"
            ),
          meta: {
            title: "系统公告详情"
          }
        }
      ]
    },
    {
      path: "/teamManagement",
      name: "TeamManagement",
      component: () =>
        import("@/views/exhibitionHallManagement/teamManagement/enter.vue"),
      meta: {
        title: "团队管理"
      }
    }
  ]
} as RouteConfigsTable;
